import React from 'react';
import './footer.css';

const Footer = () => {
  return (
  <footer className='footer'>
    Copyright &#169; 2023 Nishanth Reddy. All rights reserved.
    <p>

Hey! By the way i did my law course in Bacherlors.. </p>

  </footer>
  )
}

export default Footer;
